import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import Home from "./component/modelScene/scene_space";

import Icon2018 from "./component/modelScene/scene_2018";
import Icon2019 from "./component/modelScene/scene_2019";
import Icon2020 from "./component/modelScene/scene_2020";
import Icon2021 from "./component/modelScene/scene_2021";
import Icon2022 from "./component/modelScene/scene_2022";
import Shan1 from "./component/modelScene/scene_shan_1";
import Shan2 from "./component/modelScene/scene_shan_2";
import Shan3 from "./component/modelScene/scene_shan_3";

import './App.css';

function App() {
  return (
    <Router>
        <Routes>
          <Route exact path="/" element={<Home/>}/>
          <Route exact path="/icon/2018" element={<Icon2018/>}/>
          <Route exact path="/icon/2019" element={<Icon2019/>}/>
          <Route exact path="/icon/2020" element={<Icon2020/>}/>
          <Route exact path="/icon/2021" element={<Icon2021/>}/>
          <Route exact path="/icon/2022" element={<Icon2022/>}/>
          <Route exact path="/landscape/shan1" element={<Shan1/>}/>
          <Route exact path="/landscape/shan2" element={<Shan2/>}/>
          <Route exact path="/landscape/shan3" element={<Shan3/>}/>
        </Routes>
    </Router>
  );
}

export default App;
