
import React, { useRef} from 'react'
import { Canvas  } from '@react-three/fiber'
import { OrbitControls, Html } from '@react-three/drei'

import Space from "../model/space"
import styles from './scene.module.css';


function Box(props) {
  const mesh = useRef()
  const handleClick = ()=>{
    window.location.href=props.path
  }

  return (
    <mesh
      {...props}
      ref={mesh}
      onClick={handleClick}>
      <boxGeometry args={[1, 1, 1]} />
      <meshStandardMaterial color={'hotpink'} transparent={true} opacity={0}/>
      <Html
        style={{
          padding: "0px 8px",
          color: "white",
          fontSize:"12px"
        }}
        position={[0,0.8,0]}>
        <p>
         <a href={props.path}>
           {props.path}
         </a>
        </p>
      </Html>
    </mesh>
  )
}

export default function ModelScene({asset}) {

  return (
    <div className={styles.canvas}>
      <Canvas flat camera={{
          position: [1.5, 0, 1.5],
        }}>
        <directionalLight position={[3,3,5]} intensity={0.1}/>
        <Space castShadow receiveShadow/>
        <Box position={[-3.204,0,0.553]} scale={[0.1,1,1]} path={"/icon/2018"}/>
        <Box position={[-1.561,0.287,-2.56]} scale={[0.8,1,0.1]} path={"/icon/2019"}/>
        <Box position={[0.053,0.245,-2.566]} scale={[0.85,0.85,0.1]} path={"/icon/2020"}/>
        <Box position={[1.571,0.259,-2.566]} scale={[1,1,0.1]} path={"/icon/2021"}/>
        <Box position={[3.161,-0.244,1.082]} scale={[0.1,1,1]} path={"/icon/2022"}/>
        <Box position={[3.171,-0.805,-0.429]} scale={[0.1,1.8,2]} path={"/landscape/shan1"}/>
        <Box position={[-3.216,-0.723,-1.632]} scale={[0.1,2,2]} path={"/landscape/shan2"}/>
        <Box position={[-0.124,-1.202,-2.544]} scale={[2.3,1.1,0.1]} path={"/landscape/shan3"}/>
        <OrbitControls target={[0, 0, 0]}/>
      </Canvas>
    </div>
  )
}