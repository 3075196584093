
import { Canvas } from '@react-three/fiber'
import { OrbitControls } from '@react-three/drei'

import Shan1 from "../model/shan_1"
import styles from './scene.module.css';

import ReturnButton from '../returnButton';

export default function ModelScene({asset}) {

  return (
    <div className={styles.canvas}>
      <Canvas flat camera={{position:[0,0,3]}}>
        <ambientLight intensity={0.8}/>
          <Shan1/>
        <OrbitControls />
      </Canvas>
      <ReturnButton />
    </div>
  )
}