import styles from "./button.module.css";
import {Link} from "react-router-dom";

const ReturnButton = () =>{
    return(
        <div>
            <Link to="/">
                <div className={styles.returnButton}>
                </div>
            </Link>
        </div>
    )
}

export default ReturnButton;