import React, { useRef, Suspense } from 'react'
import { useGLTF, Html, useProgress, Environment  } from '@react-three/drei'

function Loader() {
  const { progress } = useProgress()
  return <Html center>{progress} % loaded</Html>
}

export default function Icon_2018(props) {
  const group = useRef()
  const { scene } = useGLTF('/assets/2018.glb')

//   console.log('%c ', `
//   background-image: url("https://liste.t-h-e-s-p-a-c-e.com/assets/2018.png");
//   background-size: contain;
//   font-size: 0;
//   padding: 25px;
// `,"I m 2018");

  return (
    <Suspense fallback={<Loader />}>
      <group ref={group} {...props} dispose={null}>
        <primitive object={scene} />
      </group>
      <Environment files={"STLM.hdr"} path={"/hdr/"} background />
    </Suspense>
  )
}

useGLTF.preload('assets/2018.glb')