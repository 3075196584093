import React, { useRef, Suspense } from 'react'
import { useGLTF, Html, useProgress, Environment  } from '@react-three/drei'

function Loader() {
  const { progress } = useProgress()
  return <Html center>{progress} % loaded</Html>
}

export default function Shan_2(props) {
  const group = useRef()
  const { scene } = useGLTF('/assets/shan_2.glb')

  return (
    <Suspense fallback={<Loader />}>
      <group ref={group} {...props} dispose={null}>
        <primitive object={scene} />
      </group>
      <Environment files={"bg_shan2.hdr"} path={"/hdr/"} background />
    </Suspense>
  )
}

useGLTF.preload('assets/shan_2.glb')