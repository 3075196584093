
import { Canvas } from '@react-three/fiber'
import { OrbitControls } from '@react-three/drei'

import Icon2018 from "../model/icon_2018"
import styles from './scene.module.css';

import ReturnButton from '../returnButton';


export default function ModelScene({asset}) {

  return (
    <div className={styles.canvas}>
      <Canvas flat camera={{position:[0,0,1.5]}}>
        <ambientLight intensity={0.5}/>
          <Icon2018/>
        <OrbitControls />
      </Canvas>
      <ReturnButton />
    </div>
  )
}