
import { Canvas } from '@react-three/fiber'
import { OrbitControls } from '@react-three/drei'

import Shan2 from "../model/shan_2"
import styles from './scene.module.css';

import ReturnButton from '../returnButton';

export default function ModelScene({asset}) {

  return (
    <div className={styles.canvas}>
      <Canvas flat camera={{position:[0,0,3]}}>
        <ambientLight intensity={0.75}/>
          <Shan2/>
        <OrbitControls />
      </Canvas>
      <ReturnButton />
    </div>
  )
}